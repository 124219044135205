import type { JsonDict } from '@penbox-io/stdlib'

export function shallowMerge<
  A extends undefined | null | JsonDict,
  B extends undefined | null | JsonDict,
  C extends undefined | null | JsonDict
>(
  a?: A,
  b?: B,
  c?: C
): {
  [K in keyof A | keyof B | keyof C]:
    | (K extends keyof A ? A[K] : never)
    | (K extends keyof B ? B[K] : never)
    | (K extends keyof C ? C[K] : never)
}

export function shallowMerge(
  a?: null | JsonDict,
  b?: null | JsonDict,
  c?: null | JsonDict
): JsonDict {
  if (a) {
    if (b) return c ? { ...a, ...b, ...c } : { ...a, ...b }
    return c ? { ...a, ...c } : a
  }

  if (b) return c ? { ...b, ...c } : b
  if (c) return c

  return {}
}
