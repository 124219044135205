import { Dict, flatten, isArray, isDict, isIterable } from '@penbox-io/stdlib'
import { findDefinitionParser } from '../definitions'

export type WorkflowNotificationReminderUnit = 'hours' | 'days' | 'weeks' | 'months'

export type WorkflowNotifications = {
  email?: {
    subject?: string
    body?: string
    from?: string
    cc?: string[]
    bcc?: string[]
    reply_to?: string
  }
  sms?: {
    body?: string
  }
  reminders?: Array<{
    count: number
    unit: WorkflowNotificationReminderUnit
    email?: {
      subject?: string
      body?: string
      from?: string
      cc?: string[]
      bcc?: string[]
      reply_to?: string
    }
    sms?: {
      body?: string
    }
  }>
}

export type WorkflowStep = {
  key: string
  title: string
  type: string
  assigned_to?: {
    type: string
    key: string
  } | null
}

export type WorkflowStepRequestBaseState = {
  id: string
  type: 'request'
  status: string
  completed_at?: Date | null
  active_from?: Date | null
  declined_at?: Date | null
  owner?: { email: string; given_name?: string; family_name?: string } | null
  active_until?: Date | null
  processed_at?: Date | null
  link?: string | null
  seen_at?: Date | null
  contact?: {
    email?: string
    phone?: string
    locale?: string
    family_name?: string
    given_name?: string
    internal_ref?: string
  } | null
  executed_at?: Date | null
}

export type WorkflowStepNotificationBaseState = {
  id: string
  type: 'notification'
  status: string
  error?: string | null
  sent_at?: Date | null
  scheduled_at?: Date | null
}

export type WorkflowStepRequestBaseWithNotificationsState = WorkflowStepRequestBaseState & {
  notifications: WorkflowStepNotificationState[]
}

export type WorkflowStepNotification = {
  id: string
  email?: {
    subject?: string | null
    body?: string | null
    from?: string | null
    to?: string | null
    cc?: string[] | null
    bcc?: string[] | null
    reply_to?: string | null
    status?: string | null
    statusDate?: Date | null
  } | null
  sms?: {
    to?: string | null
    body?: string | null
  } | null
  status: string
  error?: string | null
  sentAt?: Date | null
  scheduled_at?: Date | null
  isReminder: boolean
  active: boolean
}

export type WorkflowStepNotificationState = WorkflowStepNotification & { type: 'notification' }

export type ValueGetterFn = (key: string) => ValueGetterResponse | null

export type ValueGetterResponse = {
  request?: {
    id: string
    status: string
    completed_at?: Date | null
    active_from?: Date | null
    declined_at?: Date | null
    owner?: { email: string; given_name?: string; family_name?: string } | null
    active_until?: Date | null
    processed_at?: Date | null
    link?: string | null
    send_invitation_at?: Date | null
    seen_at?: Date | null
    user?: {
      email?: string
      phone?: string
      locale?: string
      family_name?: string
      given_name?: string
      internal_ref?: string
    } | null
    notifications?: WorkflowStepNotification[] | null
  } | null
  notification?: WorkflowStepNotification | null
  executed_at?: Date | null
  flow_customization?: any
  flow?: any
}

function isValidStepInput(item: unknown): item is Readonly<Dict<unknown>> & {
  type: string
  key: string
} {
  if (!isDict(item)) return false

  const type = item.type
  if (!type || typeof type !== 'string' || type === '__proto__') return false

  const key = item.key
  if (!key || typeof key !== 'string' || key === '__proto__') return false

  return true
}

export function buildWorkflowSteps(input: unknown, valueGetter?: ValueGetterFn): WorkflowStep[] {
  const steps: WorkflowStep[] = []

  const iterable = isArray(input) ? flatten(input) : isIterable(input) ? input : null

  if (!iterable) {
    return steps
  }

  for (const item of iterable) {
    try {
      const step = buildWorkflowStep(item, valueGetter)

      if (step) {
        steps.push(step)
      }
    } catch (err) {
      console.warn("Couldn't parse step", item, err)
    }
  }

  return steps
}

export function buildWorkflowStep(item: unknown, valueGetter?: ValueGetterFn): WorkflowStep | null {
  if (!item) {
    return null
  }

  if (!isValidStepInput(item)) {
    return null
  }

  const rawValue = valueGetter ? valueGetter(item.key) : null

  const definition = findDefinitionParser(item.type)

  if (!definition) {
    return null
  }

  return definition.parse(item, rawValue)
}
