import { JsonValue, Scope } from '@penbox-io/json-expression'
import type { Entity, NewEntity, JsonDict } from '@penbox-io/jsonapi-core'
import { ifString, normalizeLocale, objectGet, objectMap } from '@penbox-io/stdlib'

import { legacyAllowed, negotiateFlowLocale } from '../entities'
import type {
  Company,
  Email,
  Flow,
  FlowCustomization,
  FlowScope,
  Request,
  Response,
  RequestScope,
  Branding,
  CompanyMember,
  WorkflowScope,
  Workflow,
  WorkflowState,
  WorkflowContact,
  RequestNotification,
  RequestNotificationEvent,
  Attachment,
} from '../types'
import { shallowMerge } from '../util/shallow-merge'
import { evalFlowSteps } from './evaluator'
import { stringifyHelpable } from '@penbox-io/json-form'

export type Entities = {
  flow?: Entity<Flow>
  company?: Entity<Company>
  customization?: Entity<FlowCustomization>
  request?: Entity<Request>
  response?: Entity<Response>
  branding?: Entity<Branding>
  owner?: Entity<CompanyMember>
  workflow?: Entity<Workflow>
}

/**
 * Create a "light" scope that only ensures the existence of a "$flow" variable
 */
export function createFlowScope(
  { company, customization, flow, request, response, branding, owner }: Entities,
  {
    locales,
    locale,
    overrides = undefined,
  }: { locales?: string | string[]; locale?: string; overrides?: { options?: JsonDict } } = {}
): FlowScope {
  if (!flow || typeof flow !== 'object') {
    throw new TypeError(`A valid 'flow' entity is required to create a flow scope.`)
  }

  // Normally, when request options are set, customization options should be
  // ignored alltogether. However, flowScope is used in order to *build* the
  // options steps, which, in turn, are used to build the request options. For
  // this reason, and in order to properly use customization options as initial
  // values, we need to merge the two when creating a "flow scope".
  const options = shallowMerge(
    customization?.attributes?.options,
    request?.attributes?.options,
    overrides?.options
  )

  return {
    // Globals
    $company: company,
    $flow: flow,
    $customization: customization,
    $request: request,
    $response: response,
    $branding: createBranding(company, branding) as Entity<Branding>,

    // i18n
    $locale: locale || buildScopeLocale(flow, locales, ifString(request?.attributes?.user?.locale)),
    $defaultLocale: flow?.attributes?.locale ?? 'en',
    $strings: flow?.attributes?.strings ?? null,
    links: {
      form: request?.attributes?.link ?? null,
      app: request?.attributes?.appLink ?? null,
    },
    // Shorthand
    data: undefined,
    user: undefined,
    options,
    $reference: request?.attributes?.reference || undefined,
  }
}

export function createRequestScope(
  { company, customization, flow, request, response, branding, owner }: Entities,
  {
    overrides,
    locales,
    locale,
    strict = false,
    includeFormatted,
  }: {
    overrides?: Pick<RequestScope, 'user' | 'data' | 'options'>
    locales?: string | string[]
    locale?: string
    strict?: boolean
    includeFormatted?: boolean
  } = {}
): RequestScope & Scope {
  // Do a copy of the entities to prevent vuex issues.
  const scope = _createRequestScope(
    {
      company,
      customization,
      flow,
      request,
      response,
      branding,
      owner,
    },
    { overrides, locales, locale, strict }
  )
  if (includeFormatted === undefined) includeFormatted = true

  if (includeFormatted) {
    const steps = evalFlowSteps(scope)

    // extend the scope with the titles and formatted values of the elements
    for (const step of steps) {
      if (step?.elements) {
        for (const element of step.elements) {
          if (element?.key && element.key.startsWith('data.')) {
            const parts = element.key.split('.')
            const key = parts.pop()
            const parentPath = parts.join('.')

            const parentObject: Record<string, any> =
              parentPath.length === 0
                ? scope
                : (objectGet(scope, parentPath) as Record<string, any>)

            if (!parentObject || typeof parentObject !== 'object') {
              continue
            }

            parentObject[`${key}__title`] = stringifyHelpable(
              // @ts-expect-error
              element.title ?? element.label ?? element.options.label
            )
            parentObject[`${key}__formatted`] = element.localized
            parentObject[`${key}__type`] = element.type
            // Only set options if they are not already set
            parentObject[`${key}__options`] = element.options
          }
        }
      }
    }
  }

  return scope
}

export function _createRequestScope(
  { company, customization, flow, request, response, branding, owner }: Entities,
  {
    overrides,
    locales,
    locale,
    strict = false,
  }: {
    overrides?: Pick<RequestScope, 'user' | 'data' | 'options'>
    locales?: string | string[]
    locale?: string
    strict?: boolean
  } = {}
): RequestScope & Scope {
  if (!request || typeof request !== 'object') {
    throw new TypeError(`A valid 'request' entity is required to create a request scope.`)
  }

  const data = shallowMerge(request.attributes?.data, response?.attributes?.data, overrides?.data)
  const user = shallowMerge(request.attributes?.user, response?.attributes?.user, overrides?.user)
  const options = shallowMerge(
    // Historically, requests did not have an "options" attribute.
    // Shoud can be removed once all requests whith null options are either
    // migrated or deleted.
    request.attributes?.options || customization?.attributes?.options,
    overrides?.options
  )

  const scope: RequestScope & Scope = {
    // Globals
    $company: company,
    $flow: flow,
    $customization: customization,
    $request: request,
    $response: response,
    $responses: request.attributes?.$responses || undefined,
    $branding: createBranding(company, branding) as Entity<Branding>,
    $owner: request.attributes?.$owner,
    $creator: request.attributes?.$creator || undefined,

    // i18n
    $locale: locale || buildScopeLocale(flow, locales, ifString(user.locale)),
    $defaultLocale: flow?.attributes?.locale ?? 'en',
    $strings: flow?.attributes?.strings ?? null,
    links: {
      form: request?.attributes?.link ?? null,
      app: request?.attributes?.appLink ?? null,
    },
    // Shorthand
    data,
    user,
    options,
    signatures: response?.attributes.$signatures ?? undefined,
    $reference: request?.attributes?.reference || undefined,
  }

  // This allows access to "data." variables as global variables in json-expressions
  if (!strict && legacyAllowed(flow)) {
    for (const key in data) {
      if (key === '__proto__') continue // Just to be safe
      if (!(key in scope)) {
        scope[key] = data[key]
      }
    }
  }

  return scope
}

export function createWorkflowScope(
  entities: {
    company: Entity<Company>
    requests: Array<Entity<Request>>
    requestNotifications: Array<Entity<RequestNotification>>
    requestNotificationEvents: Array<Entity<RequestNotificationEvent>>
    workflowRequestNotifications: Array<Entity<RequestNotification>>
    workflowRequestNotificationEvents: Array<Entity<RequestNotificationEvent>>
    responses: Array<Entity<Response>>
    workflow: Entity<Workflow>
    workflowStates: Array<Entity<WorkflowState>>
    defaultFlow: Entity<Flow>
    defaultCustomization: Entity<FlowCustomization>
    workflowContacts: Array<Entity<WorkflowContact>>
    flowCustomizations: Array<Entity<FlowCustomization>>
    flows: Array<Entity<Flow>>
    emails: Array<Entity<Email>>
    emailAttachments: Array<Entity<Attachment>>
  },
  options: {
    locale?: string
  } = {}
): WorkflowScope {
  if (!entities.workflow || typeof entities.workflow !== 'object') {
    throw new Error(`A valid 'workflow' entity is required to create a workflow scope.`)
  }

  const _options = {
    ...{
      locale: 'en',
    },
    ...options,
  }

  const requestObjects: Array<{
    request: Entity<Request>
    response: Entity<Response> | undefined
    flow_customization: Entity<FlowCustomization> | undefined
    flow: Entity<Flow> | undefined
    notifications: Array<{
      notification: Entity<RequestNotification>
      events: Array<Entity<RequestNotificationEvent>>
      isReminder: boolean
    }>
  }> = []

  const workflowNotifications: Array<{
    notification: Entity<RequestNotification>
    events: Array<Entity<RequestNotificationEvent>>
  }> = []

  const state: {
    [key: string]: { id?: string; type: string; executed_at?: Date | null; data?: JsonDict }
  } = {}
  let data: JsonDict = {}

  for (const workflowState of entities.workflowStates) {
    if (workflowState.attributes.state_type === 'request') {
      const requestState = processRequestWorkflowState(entities, workflowState, _options)

      if (!requestState) {
        continue
      }

      data = shallowMerge(data, requestState.data)
      requestObjects.push({
        request: requestState.request,
        response: requestState.response,
        notifications: requestState.notifications,
        flow_customization: entities.flowCustomizations.find(
          (fc) => fc.id === requestState?.request?.relationships?.flow_customization?.data?.id
        ),
        flow: entities.flows.find(
          (f) => f.id === requestState?.request?.relationships?.flow?.data?.id
        ),
      })

      if (!workflowState.attributes.step_key) {
        throw new Error(
          `Missing step_key in workflow state request with id: ${requestState.request.id}`
        )
      }

      state[workflowState.attributes.step_key] = {
        id: requestState.request.id,
        type: 'request',
        executed_at: workflowState.attributes.$created_at,
      }
    } else if (workflowState.attributes.state_type === 'notification') {
      const notification = entities.workflowRequestNotifications.find(
        (x) => x.id === workflowState.relationships.request_notification.data.id
      )

      if (!notification) {
        continue
      }

      const events = entities.workflowRequestNotificationEvents.filter(
        (event) => event.relationships.request_notification.data.id === notification.id
      )

      workflowNotifications.push({
        notification,
        events,
      })

      if (!workflowState.attributes.step_key) {
        throw new Error(
          `Missing step_key in workflow state notification with id: ${notification.id}`
        )
      }

      state[workflowState.attributes.step_key] = {
        id: notification.id,
        type: 'notification',
        executed_at: workflowState.attributes.$created_at,
      }
    } else if (
      ['data', 'inbound-email', 'ai-extract-file'].includes(workflowState.attributes.state_type)
    ) {
      if (workflowState.attributes.data != undefined) {
        data = shallowMerge(data, workflowState.attributes.data as JsonDict)
      }
    } else if (
      workflowState.attributes.state_type === 'event' &&
      (workflowState.attributes.data as any)?.type === 'request_updated'
    ) {
      const workflowData = (workflowState.attributes.data as any)?.data
      if (workflowData?.diff) {
        data = shallowMerge(
          data,
          objectMap(workflowData.diff as JsonDict, (v: any) => v.new)
        )
      }
    }
  }

  // build contacts data
  const contacts: { [key: string]: JsonValue } = {}

  for (const contact of entities.workflowContacts) {
    contacts[contact.attributes.contact_type] = contact.attributes.$contact
  }

  return {
    $company: entities.company,
    $locale: _options.locale,
    $workflow: entities.workflow,
    $requests: requestObjects,
    $request_notifications: workflowNotifications,
    $workflow_states: entities.workflowStates,
    data,
    contacts,
    state,
    emails: entities.emails,
    emailAttachments: entities.emailAttachments,
  }
}

function processRequestWorkflowState(
  entities: {
    company: Entity<Company>
    requests: Array<Entity<Request>>
    requestNotifications: Array<Entity<RequestNotification>>
    requestNotificationEvents: Array<Entity<RequestNotificationEvent>>
    responses: Array<Entity<Response>>
    defaultFlow: Entity<Flow>
    defaultCustomization: Entity<FlowCustomization>
    flowCustomizations: Array<Entity<FlowCustomization>>
    flows: Array<Entity<Flow>>
  },
  workflowState: Entity<WorkflowState>,
  options: {
    locale?: string
  }
) {
  const request = entities.requests.find(
    (x) => x.id === workflowState.relationships.request.data.id
  )

  if (!request) {
    return
  }

  const response = entities.responses.find(
    (response: Entity<Response>) => response.relationships.request.data.id === request.id
  )

  const notifications = entities.requestNotifications
    .filter((notification) => notification.relationships?.request?.data?.id === request.id)
    .map((notification) => ({
      notification,
      events: entities.requestNotificationEvents.filter(
        (event) => event.relationships.request_notification.data.id === notification.id
      ),
      isReminder: notification?.meta?.['pnbx:notification:reminder'] === true,
    }))

  const requestScope = createRequestScope(
    {
      company: entities.company,
      customization:
        entities.flowCustomizations.find(
          (fc) => fc.id === request.relationships.flow_customization?.data?.id
        ) || entities.defaultCustomization,
      flow:
        entities.flows.find((f) => f.id === request.relationships.flow?.data?.id) ||
        entities.defaultFlow,
      request,
      response,
    },
    {
      includeFormatted: true,
      locale: options.locale,
    }
  )

  return {
    data: requestScope.data,
    request,
    response,
    notifications,
  }
}

export function buildScopeLocale(
  flow?: NewEntity<Flow>,
  locales?: string | string[],
  userLocale?: string
): string {
  if (flow) {
    if (userLocale) {
      const locale = negotiateFlowLocale(flow, userLocale)
      if (locale) return locale
    }
    if (locales?.length) {
      const locale = negotiateFlowLocale(flow, locales)
      if (locale) return locale
    }
    const locale = ifString(flow.attributes?.locale)
    if (locale) return locale
  } else {
    const locale = normalizeLocale(userLocale || (Array.isArray(locales) ? locales[0] : locales))
    if (locale) return locale
  }

  return 'en'
}

const createBranding = (
  company: Entity<Company> | undefined = undefined,
  branding: Entity<Branding> | undefined = undefined
) => {
  if (!company && !branding) return undefined
  return (
    branding ?? {
      attributes: {
        slug: 'default',
        name: 'Default',
        logo: company?.attributes?.logo ?? null,
        icon: company?.attributes?.icon ?? null,
        favicon: company?.attributes?.favicon ?? null,
        colors: company?.attributes?.colors ?? null,
        contact: company?.attributes?.contact ?? null,
        links: company?.attributes?.links ?? null,
      },
    }
  )
}
