import form from './form'
import email from './email'

export function findDefinitionParser(type: string) {
  switch (type) {
    case 'form':
      return form
    case 'email':
      return email
    default:
      return null
  }
}
